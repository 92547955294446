import React from "react";
import styled from "styled-components/macro";
import getUniqueColor from "foundations/utilities/getUniqueColor";
import { setLightness } from "polished";
import { OwlAvatar } from "./icons/OwlAvatar";
import { useSelector } from "react-redux";
import { getUsersById } from "features/users/userModel";
import { getLoggedInUserId } from "features/authentication/authenticationModel";

export const Wrapper = styled.div<{ color: string; size: number }>`
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 400;
  border-radius: 50%;
  text-align: center;
  color: white;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: ${props => props.color};
  width: ${props => props.size}px;
  font-size: ${props => Math.round(props.size / 3)}px;
  height: ${props => props.size}px;
  line-height: ${props => props.size}px;
`;

const colorSet = [
  "#FFAB91",
  "#80DEEA",
  "#EF9A9A",
  "#CE93D8",
  "#AED581",
  "#9FA7DF",
  "#BCAAA4",
  "#FFE082",
  "#F48FB1",
  "#DCE775"
];

type UserInitialsAvatarProps = {
  name: string;
  userId: string;
  size: number;
  muted?: boolean;
  color?: string;
};

const UserInitialsAvatar = ({
  name,
  userId,
  size,
  muted,
  color
}: UserInitialsAvatarProps) => {
  const usersById = useSelector(getUsersById);
  const currentUserId = useSelector(getLoggedInUserId);
  const user = usersById[currentUserId];
  if (!name || !userId) {
    name = user.name;
    userId = user.id;
  }
  const initials = name.match(/\b\w/g) || ["U"];
  const uniqueColor = getUniqueColor(userId, colorSet);
  const processedColor = muted ? setLightness(0.9, uniqueColor) : uniqueColor;
  const isChatBot = userId === "UTB Owl ChatBot";
  // todo better way of conditional rendering (todo remove magic string)
  let avatar;
  if (isChatBot) {
    avatar = (
      <OwlAvatar
        id={userId}
        height={size}
        width={size}
        title={initials.join("")}
      />
    );
  } else {
    avatar = (
      <Wrapper size={size} color={color || processedColor}>
        {initials}
      </Wrapper>
    );
  }

  return avatar;
};

export { UserInitialsAvatar };
