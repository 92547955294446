import React from "react";
import { Wrapper } from "./ChatUI.style";
import { Menu } from "features/chat/Menu/Menu";
import { CurrentConversation } from "features/currentConversation/CurrentConversation/CurrentConversation";
import { ConversationMembers } from "features/conversationMembers/ConversationMembers/ConversationMembers";
import { JoinConversationDialog } from "features/joinedConversations/JoinConversationDialog/JoinConversationDialog";
import { ConversationHistoryDialog } from "features/joinedConversations/ConversationHistoryDialog/ConversationHistoryDialog";
import { GeneralConversationClipboardHistoryDialog } from "features/joinedConversations/GeneralConversationClipboardHistoryDialog";

const ChatUI = () => {
  return (
    <Wrapper>
      <Menu />
      <CurrentConversation />
      <ConversationMembers />
      <JoinConversationDialog />
      <ConversationHistoryDialog />
      <GeneralConversationClipboardHistoryDialog />
    </Wrapper>
  );
};

export { ChatUI };
