import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MessageListItem, MessageFragment } from "../MessageListItem";
import {
  getCurrentConversationId,
  DEFAULT_CONVERSATION
} from "../currentConversationModel";
import { getUsersById, UsersIndexedById } from "features/users/userModel";
import { getMessagesById } from "features/messages/messageModel";
import { Wrapper } from "./MessageList.style";
import { UserInitialsAvatar } from "foundations/components/UserInitialsAvatar";
import { fetchMessageHistory, fetchMembers } from "pubnub-redux";
import { useDispatch } from "main/useDispatch";
import { AppDispatch } from "main/storeTypes";
import { getLoggedInUserId } from "features/authentication/authenticationModel";
import { addSpaceMembers } from "../../conversationMembers/addSpaceMembersCommand";

/**
 * Create a selector that that returns the list of messages in the currentConversation joined
 * to the user that sent that message
 *
 * TODO:
 * This implementation will cause the dependant component to re-render if any user data has changed
 * if the current conversation has changed or if any message has changed or if any user has changed.
 * This needs to be reduced in scope
 *
 * TODO: This needs to sort by time token; object keys are not guarenteed to retain order in js.
 */
export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users): MessageFragment[] => {
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter(message => message.channel === conversationId)
          .map(
            (message): MessageFragment => {
              return {
                ...message,
                timetoken: String(message.timetoken),
                sender:
                  users[message.message.senderId] ||
                  (message.message.senderId
                    ? {
                        id: message.message.senderId,
                        name: message.message.senderId
                      }
                    : {
                        id: "unknown",
                        name: "unknown"
                      })
              };
            }
          )
      : [];
  }
);

let fetchingHistory = false;

const MessageList = () => {
  type ConversationScrollPositionsType = { [conversationId: string]: number };
  const conversationId: string = useSelector(getCurrentConversationId);
  const dispatch = useDispatch();
  const [
    conversationsScrollPositions,
    setConversationsScrollPositions
  ] = useState<ConversationScrollPositionsType>({});

  const updateCurrentConversationScrollPosition = (scrollPosition: number) => {
    setConversationsScrollPositions({
      ...conversationsScrollPositions,
      [conversationId]: scrollPosition
    });
  };

  const handleScroll = (e: any) => {
    const scrollPosition = e.target.scrollTop;
    if (scrollPosition !== 0) {
      updateCurrentConversationScrollPosition(scrollPosition);
    }
  };

  const restoreConversationScrollPosition = (conversationId: string) => {
    const conversationScrollPosition: number =
      conversationsScrollPositions[conversationId];
    if (conversationScrollPosition) {
      wrapper.current.scrollTo(0, conversationScrollPosition);
    }
  };

  const memoizedRestoreConversationScrollPositionCallback = useCallback(
    restoreConversationScrollPosition,
    [conversationId]
  );

  const messages: MessageFragment[] = useSelector(
    getCurrentConversationMessages
  );

  const restoreHistory = (
    dispatch: AppDispatch,
    conversationId: string,
    messages: MessageFragment[]
  ) => {
    let lastMessageTimetoken = Date.now() * 10000;

    // TODO delete messages from store to avoid duplicate keys
    // i.e. timetoken and treat pubnub as definitive source of truth
    if (
      !fetchingHistory &&
      conversationId !== DEFAULT_CONVERSATION &&
      messages.length === 0
    ) {
      fetchingHistory = true;

      dispatch(
        fetchMessageHistory({
          channel: conversationId,
          includeMeta: true,
          stringifiedTimeToken: true,
          start: String(lastMessageTimetoken)
        })
      ).then(() => (fetchingHistory = false));
    }
  };

  const memoizedRestoreHistoryCallback = useCallback(restoreHistory, []);

  const getUniqueBrokerIds = (
    loggedInUser: string,
    messages: MessageFragment[]
  ) => {
    const r = ["\\d{1,}"];
    const senders = messages.map(message => message.sender);
    const filteredMembers = senders
      .filter(sender => sender.id !== loggedInUser)
      .filter(sender => r.some(regex => sender.id.match(regex)))
      .map(sender => sender.id);
    const senderIds = [...new Set(filteredMembers)];

    return senderIds;
  };

  const includeBroker = (
    dispatch: AppDispatch,
    currentConversationId: string,
    loggedInUser: string,
    usersById: UsersIndexedById,
    messages: MessageFragment[]
  ) => {
    const senderIds = getUniqueBrokerIds(loggedInUser, messages);

    senderIds.forEach(id => {
      const user = usersById[id];
      if (!user) {
        dispatch(
          fetchMembers({
            spaceId: currentConversationId,
            include: {
              userFields: true,
              customUserFields: true,
              totalCount: false
            }
          })
        ).then(() => {
          if (!user) {
            dispatch(addSpaceMembers(id, conversationId));
          }
        });
      }
    });
  };

  const loggedInUser = useSelector(getLoggedInUserId);
  const usersById = useSelector(getUsersById);
  const currentConversationId = useSelector(getCurrentConversationId);

  const memoizedIncludeBrokerCallback = useCallback(includeBroker, [
    dispatch,
    currentConversationId,
    loggedInUser,
    usersById,
    messages
  ]);

  useEffect(() => {
    memoizedIncludeBrokerCallback(
      dispatch,
      currentConversationId,
      loggedInUser,
      usersById,
      messages
    );
  }, [
    memoizedIncludeBrokerCallback,
    dispatch,
    currentConversationId,
    loggedInUser,
    usersById,
    messages
  ]);

  const wrapper = useRef<HTMLDivElement>(document.createElement("div"));
  const el = wrapper.current;

  const scrollToBottom = useCallback(() => {
    return (el.scrollTop = el.scrollHeight - el.clientHeight - 1);
  }, [el]);

  const scrollToBottomAutomatedMessage = useCallback(() => {
    return (el.scrollTop = el.scrollHeight - el.clientHeight);
  }, [el]);

  const hasReachedBottomAutomatedMessage =
    el.scrollHeight - el.clientHeight === Math.round(el.scrollTop);

  const hasReachedBottom =
    el.scrollHeight - el.clientHeight - 1 === Math.round(el.scrollTop);

  useEffect(() => {
    if (hasReachedBottom) {
      // new message from chat bot does not push scroll to bottom
      scrollToBottom();
    } else if (hasReachedBottomAutomatedMessage) {
      scrollToBottomAutomatedMessage();
    }
  }, [
    messages.length,
    hasReachedBottom,
    scrollToBottom,
    hasReachedBottomAutomatedMessage,
    scrollToBottomAutomatedMessage
  ]);

  useEffect(() => {
    memoizedRestoreConversationScrollPositionCallback(conversationId);
  }, [memoizedRestoreConversationScrollPositionCallback, conversationId]);

  useEffect(() => {
    memoizedRestoreHistoryCallback(dispatch, conversationId, messages);
  });

  return (
    <Wrapper ref={wrapper} onScroll={handleScroll}>
      {messages.map(message => (
        <MessageListItem
          messageFragment={message}
          key={message.timetoken}
          avatar={
            <UserInitialsAvatar
              size={36}
              name={message.sender.name}
              userId={message.sender.id}
            />
          }
        />
      ))}
    </Wrapper>
  );
};

export { MessageList };
