import { ThunkAction } from "main/storeTypes";
import { playback, Custom } from "main/global";
import getApiToken from "foundations/utilities/getApiToken";

export const saveChatHistory = (
  userId: string,
  conversationId: string,
  saveToNotes: boolean
): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(
        () => {
          getApiToken()
            .then(token => {
              const { messages, conversations, users } = getState();

              let custom: Custom =
                conversations.conversations.byId[conversationId]?.custom;

              let msgs = messages.byId[conversationId];
              let messageHistory: any = msgs;

              let channelHistory: playback = {
                applicationId: Number(custom.applicationId),
                brokerId: Number(custom.brokerId),
                saveToNotes: saveToNotes,
                brokerName: custom.brokerName,
                contextId: custom.contextId,
                underwriter: users.byId[userId]?.name || "",
                summary: JSON.stringify(msgs),
                historyResult: messageHistory // todo assign - look at definition of messageEnvelope - map to C#
              };

              let channelHistoryString = JSON.stringify(channelHistory);

              const url = `${process.env.REACT_APP_API_URL}/api/Histories`;

              const request = new Request(url, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json; charset=UTF-8"
                },
                cache: "no-cache",
                body: channelHistoryString
              });

              fetch(request);
            })
            .catch(e => reject(e));
        },
        (status: any) => {
          if (!status.error) {
            resolve();
          } else {
            if (status.statusCode === 409) {
              //skip duplicate users ie that already exists
              resolve();
            } else {
              reject();
            }
          }
        },
        1000
      );
    });
  };
};
