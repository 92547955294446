import React from "react";
import { Wrapper, Body, Information, Note, Border } from "./Footer.style";

const Footer = () => {
  return (
    <Wrapper>
      <Body>
        <Information>
          <Note>
            <b>Note</b> all conversations are recorded
          </Note>
        </Information>
      </Body>
      <Border />
    </Wrapper>
  );
};

export { Footer };
