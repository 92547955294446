import { ThunkAction } from "main/storeTypes";
import { leaveSpaces, deleteSpace } from "pubnub-redux";
import {
  DEFAULT_CONVERSATION,
  focusOnConversation
} from "features/currentConversation/currentConversationModel";
import { TextMessage, MessageType } from "features/messages/messageModel";
import { sendMessage } from "features/messages/sendMessage";
import { saveLastKnownChatHistory } from "./saveLastKnownChatHistoryCommand";

/**
 * Leave the current conversation and select the default conversation
 * as the current conversation.  (The application expects that some
 * conversation will always be current so allow underwriters to chat with each other.)
 */
export const leaveConversation = (
  userId: string,
  conversationId: string,
  saveToNotes: boolean
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    if (conversationId === DEFAULT_CONVERSATION) {
      return Promise.resolve();
    }

    return dispatch(
      leaveSpaces({
        userId: userId,
        spaces: [{ id: conversationId }]
      })
    ).then(() => {
      const state = getState();
      const members = state.conversationMembers.byId[conversationId];
      const userName =
        members.find(m => m.id === userId)?.user?.name ??
        state.users.byId[userId]?.name ??
        "An underwriter";

      const shouldCloseConversation =
        members.filter(m => m.id !== userId).length <= 1;

      const text = shouldCloseConversation
        ? "Thanks for your enquiry, this Webchat has now been closed."
        : `${userName} has left the chat.`;

      const message: TextMessage = {
        type: MessageType.Text,
        senderId: "UTB Owl ChatBot",
        text: text
      };

      dispatch(sendMessage(message));

      if (shouldCloseConversation) {
        dispatch(saveLastKnownChatHistory(userId, conversationId, saveToNotes));
      }

      context.pubnub.api.unsubscribe({
        channels: [conversationId]
      });

      dispatch(focusOnConversation(DEFAULT_CONVERSATION));

      if (shouldCloseConversation) {
        dispatch(
          deleteSpace({
            spaceId: conversationId
          })
        );
      }
    });
  };
};
