import { ThunkAction } from "main/storeTypes";
import { createPubnubUser } from "./createPubnubUserCommand";

export const manageLoggingInUser = (
  payload: any
): ThunkAction<Promise<void>> => {
  console.log(`payload: ${JSON.stringify(payload)}`);

  return (dispatch, getState, context) => {
    return dispatch(createPubnubUser(payload));
  };
};
