import { ThunkAction } from "main/storeTypes";
import {
  DEFAULT_CONVERSATION,
  focusOnConversation
} from "features/currentConversation/currentConversationModel";
import { addSpaceMembers } from "features/conversationMembers/addSpaceMembersCommand";
import { createChatUser } from "features/users/createChatUserCommand";
import { joinChatSpace } from "features/conversations/joinChatSpaceCommand";

export const createPubnubUser = (payload: any): ThunkAction<Promise<void>> => {
  console.log(JSON.stringify(payload));
  console.log(`${payload.given_name} ${payload.family_name}`);
  return (dispatch, getState, context) => {
    // return dispatch(
    //   createUser({
    //     id: payload.nameid.toString(),
    //     name: `${payload.given_name} ${payload.family_name}`,
    //     // profileUrl: "~/webchat/logo192.png",
    //     email: payload.email,
    //     custom: {
    //       email: payload.email
    //     }
    //   })
    // )
    return dispatch(
      createChatUser(
        String(payload.nameid),
        `${payload.given_name} ${payload.family_name}`
      )
    )
      .then(() => {
        // dispatch(
        //   addMembers({
        //     spaceId: DEFAULT_CONVERSATION,
        //     users: [payload.nameid.toString()]
        //   })
        // );
        dispatch(addSpaceMembers(String(payload.nameid), DEFAULT_CONVERSATION));
      })
      .then(() => {
        // dispatch(
        //   joinSpaces({
        //     userId: payload.nameid.toString(),
        //     spaces: [{ id: DEFAULT_CONVERSATION }]
        //   })
        // );
        dispatch(joinChatSpace(String(payload.nameid), DEFAULT_CONVERSATION));
      })
      .then(() => {
        context.pubnub.api.subscribe({
          channelGroups: ["GeneralEnquiry", "CaseEnquiry"],
          withPresence: true
        });
        dispatch(focusOnConversation(DEFAULT_CONVERSATION));
      });
  };
};
