import React from "react";
import { useDispatch } from "main/useDispatch";
import {
  Wrapper,
  Button,
  PoweredByPubNub,
  PoweredBy,
  Body
} from "./Login.style";
import PubNubLogo from "./PubNub_Logo.svg";
import { login } from "../loginCommand";
import { isLoggingIn } from "../authenticationModel";
import { isUserLoggedIn } from "features/authentication/authenticationModel";
import { useSelector } from "react-redux";
import { getUsersById, User } from "features/users/userModel";
import { manageLoggingInUser } from "../manageLoggingInUser";
import jwtDecode from "../../../foundations/utilities/jwtDecode";

const Login = () => {
  const dispatch = useDispatch();
  const loggingIn = useSelector(isLoggingIn);
  const loggedIn = useSelector(isUserLoggedIn);
  const usersById = useSelector(getUsersById);

  const tokenLogin = () => {
    if (loggingIn || loggedIn) {
      return;
    }

    const payload = jwtDecode();
    const userId = payload.nameid;
    const user: User = usersById[userId];

    if (!!user || user === undefined) {
      dispatch(manageLoggingInUser(payload))
        .then(() => {
          dispatch(login(userId));
        })
        .catch(error => {
          console.error(JSON.stringify(error));
          dispatch(login(userId));
        });
    } else {
      dispatch(login(userId));
    }
  };

  if (!loggedIn && !loggingIn) {
    tokenLogin();
  }

  return (
    <Wrapper>
      <Body>
        <Button onClick={tokenLogin}>
          {loggingIn ? "Connecting" : "Connect"}
        </Button>
        <PoweredByPubNub>
          <PoweredBy>Powered By</PoweredBy>
          <img alt="PubNub" src={PubNubLogo} />
        </PoweredByPubNub>
      </Body>
    </Wrapper>
  );
};

export { Login };
