import { LayoutActionType } from "./LayoutActionType";
import { ActionMeta } from "pubnub-redux";
import { SpaceCreatedAction } from "pubnub-redux/dist/features/space/SpaceActions";
import { SpaceCreatedEnvelope } from "features/joinedConversations/joinableConversationModel";

export interface menuViewHiddenAction {
  type: typeof LayoutActionType.MENU_VIEW_HIDDEN;
}

export interface currentConversationViewHiddenAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_VIEW_HIDDEN;
}

export interface conversationMembersViewHiddenAction {
  type: typeof LayoutActionType.CONVERSATION_MEMBERS_VIEW_HIDDEN;
}

export interface joinConversationViewHiddenAction {
  type: typeof LayoutActionType.JOIN_CONVERSATION_VIEW_HIDDEN;
}

export interface leaveConversationViewHiddenAction {
  type: typeof LayoutActionType.LEAVE_CONVERSATION_VIEW_HIDDEN;
}

export interface saveConversationHistoryViewHiddenAction {
  type: typeof LayoutActionType.SAVE_CONVERSATION_HISTORY_VIEW_HIDDEN;
}

export interface saveConversationHistoryViewDisplayedAction {
  type: typeof LayoutActionType.SAVE_CONVERSATION_HISTORY_VIEW_DISPLAYED;
}

export interface saveConversationClipboardHistoryViewHiddenAction {
  type: typeof LayoutActionType.SAVE_CONVERSATION_CLIPBOARD_HISTORY_VIEW_HIDDEN;
}

export interface saveConversationClipboardHistoryViewDisplayedAction {
  type: typeof LayoutActionType.SAVE_CONVERSATION_CLIPBOARD_HISTORY_VIEW_DISPLAYED;
}

export interface menuViewDisplayedAction {
  type: typeof LayoutActionType.MENU_VIEW_DISPLAYED;
}

export interface currentConversationViewDisplayedAction {
  type: typeof LayoutActionType.CURRENT_CONVERSATION_VIEW_DISPLAYED;
}

export interface conversationMembersViewDisplayedAction {
  type: typeof LayoutActionType.CONVERSATION_MEMBERS_VIEW_DISPLAYED;
}

export interface joinConversationViewDisplayedAction {
  type: typeof LayoutActionType.JOIN_CONVERSATION_VIEW_DISPLAYED;
}

export interface leaveConversationViewDisplayedAction {
  type: typeof LayoutActionType.LEAVE_CONVERSATION_VIEW_DISPLAYED;
}

// export interface notificationSpaceCreatedAction {
// type: typeof SpaceActionType.SPACE_CREATED;
// payload: SpaceEvent;
// }

// export interface notificationSpaceCreatedAction<ReceivedSpace extends Space<ObjectsCustom>, Meta extends ActionMeta
// > {
// type: typeof SpaceActionType.SPACE_CREATED;
// payload: SpaceEvent;
// }

// export type notificationActions<
//   ReceivedSpace extends Space<ObjectsCustom>,
//   Meta extends ActionMeta
// > =
//   | notificationSpaceCreatedAction<ReceivedSpace, Meta>;

export type LayoutActions =
  | menuViewHiddenAction
  | currentConversationViewHiddenAction
  | conversationMembersViewHiddenAction
  | joinConversationViewHiddenAction
  | leaveConversationViewHiddenAction
  | menuViewDisplayedAction
  | currentConversationViewDisplayedAction
  | conversationMembersViewDisplayedAction
  | joinConversationViewDisplayedAction
  | leaveConversationViewDisplayedAction
  | saveConversationHistoryViewHiddenAction
  | saveConversationHistoryViewDisplayedAction
  | saveConversationClipboardHistoryViewHiddenAction
  | saveConversationClipboardHistoryViewDisplayedAction
  | SpaceCreatedAction<SpaceCreatedEnvelope, ActionMeta>;

// export const notificationSpaceCreated = <ReceivedSpace extends Space<ObjectsCustom>, Meta extends ActionMeta>(
// export const notificationSpaceCreated = (payload: SpaceEvent): notificationSpaceCreatedAction => {
//   return {
//     type: SpaceActionType.SPACE_CREATED,
//     payload: payload // todo
//   };
// };

export const menuViewDisplayed = (): menuViewDisplayedAction => {
  return {
    type: LayoutActionType.MENU_VIEW_DISPLAYED
  };
};

export const joinConversationViewDisplayed = (): joinConversationViewDisplayedAction => {
  return {
    type: LayoutActionType.JOIN_CONVERSATION_VIEW_DISPLAYED
  };
};

export const leaveConversationViewDisplayed = (): leaveConversationViewDisplayedAction => {
  return {
    type: LayoutActionType.LEAVE_CONVERSATION_VIEW_DISPLAYED
  };
};

export const currentConversationViewDisplayed = (): currentConversationViewDisplayedAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_VIEW_DISPLAYED
  };
};

export const conversationMembersViewDisplayed = (): conversationMembersViewDisplayedAction => {
  return {
    type: LayoutActionType.CONVERSATION_MEMBERS_VIEW_DISPLAYED
  };
};

export const menuViewHidden = (): menuViewHiddenAction => {
  return {
    type: LayoutActionType.MENU_VIEW_HIDDEN
  };
};

export const joinConversationViewHidden = (): joinConversationViewHiddenAction => {
  return {
    type: LayoutActionType.JOIN_CONVERSATION_VIEW_HIDDEN
  };
};

export const leaveConversationViewHidden = (): leaveConversationViewHiddenAction => {
  return {
    type: LayoutActionType.LEAVE_CONVERSATION_VIEW_HIDDEN
  };
};

export const saveConversationHistoryViewDisplayed = (): saveConversationHistoryViewDisplayedAction => {
  return {
    type: LayoutActionType.SAVE_CONVERSATION_HISTORY_VIEW_DISPLAYED
  };
};

export const saveConversationHistoryViewHidden = (): saveConversationHistoryViewHiddenAction => {
  return {
    type: LayoutActionType.SAVE_CONVERSATION_HISTORY_VIEW_HIDDEN
  };
};

export const saveConversationClipboardHistoryViewHidden = (): saveConversationClipboardHistoryViewHiddenAction => {
  return {
    type: LayoutActionType.SAVE_CONVERSATION_CLIPBOARD_HISTORY_VIEW_HIDDEN
  };
};

export const saveConversationClipboardHistoryViewDisplayed = (): saveConversationClipboardHistoryViewDisplayedAction => {
  return {
    type: LayoutActionType.SAVE_CONVERSATION_CLIPBOARD_HISTORY_VIEW_DISPLAYED
  };
};

export const currentConversationViewHidden = (): currentConversationViewHiddenAction => {
  return {
    type: LayoutActionType.CURRENT_CONVERSATION_VIEW_HIDDEN
  };
};

export const conversationMembersViewHidden = (): conversationMembersViewHiddenAction => {
  return {
    type: LayoutActionType.CONVERSATION_MEMBERS_VIEW_HIDDEN
  };
};
