import React, { useContext } from "react";
import { getViewStates } from "features/layout/Selectors";
import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUserId } from "features/authentication/authenticationModel";
import {
  ConversationDescription,
  ConversationDescriptionFragment
} from "../ConversationDescription";
import {
  getConversationsByUserId,
  MembershipHash
} from "../joinedConversationModel";
import { CrossIcon } from "foundations/components/icons/CrossIcon";
import {
  ScrollView,
  CloseButton,
  Title,
  Header
} from "./JoinConversationDialog.style";
import {
  Overlay,
  Modal,
  getAnimatedModalVariants
} from "foundations/components/Modal";
import { createSelector } from "reselect";
import {
  getAllConversations,
  Conversation
} from "features/conversations/conversationModel";
import { joinConversation } from "../joinConversationCommand";
import { joinConversationViewHidden } from "features/layout/LayoutActions";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "foundations/hooks/useMediaQuery";
import { getUsersById } from "features/users/userModel";
import getTimestamp from "foundations/utilities/getTimestamp";
import createHashId from "foundations/utilities/createHashId";

// Fetch all conversations and remove the ones we're already a member of
const getJoinableConversations = createSelector(
  [getAllConversations, getLoggedInUserId, getConversationsByUserId],
  (
    conversations: Conversation[],
    userId: string,
    joinedConversations: MembershipHash
  ): ConversationDescriptionFragment[] => {
    const tenMinutes = 1000 * 60 * 10;
    const now = Date.now();

    // Fixes id, name not found. Removed ?.id and ?.name from codebase
    return conversations.filter(conversation => {
      return (
        conversation &&
        conversation.hasOwnProperty("id") &&
        conversation.hasOwnProperty("name") &&
        now - new Date((conversation as any).created).valueOf() < tenMinutes &&
        !joinedConversations[userId]
          .map(conv => conv.id)
          .includes(conversation.id)
      );
    });
  }
);

/**
 * Present list to the user of conversations that they could join, but have not.
 * Allow the user to select the conversation to join or back out.
 *
 * TODO: This renders unconditionally as display:none so it will fetch the
 * list of conversations to join when the UI is rendered even if the user has not
 * opened the dialog.
 */
const JoinConversationDialog = () => {
  const conversations: ConversationDescriptionFragment[] = useSelector(
    getJoinableConversations
  );
  const views = useSelector(getViewStates);
  const currentUserId = useSelector(getLoggedInUserId);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const isSmall = useMediaQuery(themeContext.breakpoint.mediaQuery.small);
  const usersById = useSelector(getUsersById);
  const user = usersById[currentUserId];
  const hash = createHashId(String(getTimestamp()));

  return (
    <Overlay displayed={views.JoinConversation}>
      <Modal
        animate={views.JoinConversation ? "open" : "closed"}
        variants={getAnimatedModalVariants(isSmall)}
      >
        <Header>
          <Title>Accept a Chat Session</Title>
          <CloseButton
            onClick={() => {
              dispatch(joinConversationViewHidden());
            }}
          >
            <CrossIcon title="close" />
          </CloseButton>
        </Header>
        <ScrollView>
          {conversations.map(conversation => (
            <ConversationDescription
              key={`${conversation.id}-${hash}`}
              onClick={() => {
                if (conversation) {
                  const conversationId = conversation.id;
                  const username = user.name;
                  dispatch(
                    joinConversation(currentUserId, conversationId, username)
                  );
                  dispatch(joinConversationViewHidden());
                } else {
                  console.log("conversation is undefined");
                }
              }}
              conversation={conversation}
            />
          ))}
        </ScrollView>
      </Modal>
    </Overlay>
  );
};

export { JoinConversationDialog };
