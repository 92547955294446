import styled, { StyledFunction } from "styled-components/macro";

interface WrapperProps {
  hasMembers: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  color: #565656;
  font-family: Verdana, Geneva, sans-serif;
  padding: 10px 20px 10px 20px;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: white;
  :hover {
    background-color: #f0f3f7;
  }
  border-left: ${props =>
    props.hasMembers ? "3px solid #4caf50" : "3px solid lightgrey"};
`;

export const Body = styled.div`
  display: flex;
`;

export const Name = styled.div`
  margin: 5px 20px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Description = styled.div`
  margin: 5px 20px;
  color: #aeafae;
`;

export const MetaData = styled.div`
  margin: 5px 20px;
  color: #aeafae;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  padding: 11px 0;
`;

export const ConversationIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 10px;
  padding: 11px 13px;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 500;
  font-size: 13px;
  background-color: #f0f3f7;
`;

export const UtbUser = styled.div`
  margin: 5px 20px;
  color: #4caf50;
`;
