import React from "react";

import { Wrapper } from "./style";
import { Login } from "features/authentication/Login/Login";
import { ChatUI } from "features/chat/Chat";
import { isUserLoggedIn } from "features/authentication/authenticationModel";
import { useSelector } from "react-redux";
import { ErrorBanner } from "main/styles/ErrorBanner.style";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const ErrorBannerComponent = (
  <ErrorBanner>
    <div>
      Please run <pre>npm setup</pre>
    </div>
  </ErrorBanner>
);

export const ApplicationRouter = () => {
  const loggedIn = useSelector(isUserLoggedIn);
  const view = loggedIn ? <ChatUI /> : <Login />;
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Wrapper>
            {!process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY &&
              ErrorBannerComponent}
            {view}
          </Wrapper>
        </Route>
      </Switch>
    </Router>
  );
};
