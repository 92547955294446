import jwt_decode from "jwt-decode";

const secureRandom = require("secure-random");
const njwt = require("njwt");

export default () => {
  const params = new URLSearchParams(window.location.search);
  const t = params.get("t");

  let payload: any;

  if (t) {
    payload = jwt_decode(t);
  } else {
    if (process.env.REACT_APP_ENV === "Development") {
      payload = createDevUserToken();
    } else {
      throw "auth token could not be found";
    }
  }

  return payload;
};

const createDevUserToken = () => {
  const signingKey = secureRandom(256, { type: "Buffer" });

  const claims = {
    iss: "http://localhost:3000/",
    sub: "Demo Underwriter",
    scope: "self, admins",
    nameid: "4620",
    email: "demo@underwriter.com",
    first_name: "Demo",
    family_name: "Account"
  };

  const token = njwt.create(claims, signingKey);
  const t = token.compact();

  njwt.verify(t, signingKey, (err: any, verifiedJwt: any) => {
    if (err) {
      console.log(err);
    } else {
      return verifiedJwt;
    }
  });

  return jwt_decode(t);
};
