import React, { useContext } from "react";
import { getViewStates } from "features/layout/Selectors";
import { useSelector, useDispatch } from "react-redux";
import { getLoggedInUserId } from "features/authentication/authenticationModel";
import {
  ModalDialog,
  AcceptButton,
  DeclineButton,
  Row,
  StyledText,
  Overlay
} from "./GeneralConversationClipboardHistoryDialog.style";
import { getAnimatedModalVariants } from "foundations/components/Modal";
import { leaveConversation } from "../leaveConversationCommand";
import { saveConversationClipboardHistoryViewHidden } from "features/layout/LayoutActions";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "foundations/hooks/useMediaQuery";
import {
  getCurrentConversationId,
  getCurrentConversationCustomData
} from "features/currentConversation/currentConversationModel";
import useCopy from "@react-hook/copy";
import { getCurrentConversationMessages } from "features/currentConversation/MessageList";
import convertTimestampToDatetime from "foundations/utilities/convertTimestampToDatetime";

/**
 * Present list to the user of conversations that they could join, but have not.
 * Allow the user to select the conversation to join or back out.
 *
 * TODO: This renders unconditionally as display:none so it will fetch the
 * list of conversations to join when the UI is rendered even if the user has not
 * opened the dialog.
 */
const GeneralConversationClipboardHistoryDialog = () => {
  const views = useSelector(getViewStates);
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const isSmall = useMediaQuery(themeContext.breakpoint.mediaQuery.small);
  const currentUserId = useSelector(getLoggedInUserId);
  const currentConversationId = useSelector(getCurrentConversationId);

  const messages = useSelector(getCurrentConversationMessages);
  let m = messages.map((value, index, array) => {
    let t = value.message.text;
    let s = value.sender.name;
    let d = convertTimestampToDatetime(value.timetoken);

    return `[${d}] ${s}: ${t}`;
  });

  const customData = useSelector(getCurrentConversationCustomData);

  let notes = {
    summary: `Chat History (with ${customData?.brokerName})`,
    note: m
  };

  const msgs = JSON.stringify(notes);
  const { copy } = useCopy(msgs);

  return (
    <Overlay displayed={views.SaveConversationClipboardHistory}>
      <ModalDialog
        animate={views.SaveConversationClipboardHistory ? "open" : "closed"}
        variants={getAnimatedModalVariants(isSmall)}
      >
        <StyledText>
          Would you like to save this chat history to the clipboard?
        </StyledText>
        <Row>
          <AcceptButton
            onClick={() => {
              dispatch(saveConversationClipboardHistoryViewHidden());
              dispatch(
                leaveConversation(currentUserId, currentConversationId, true)
              );
              dispatch(copy);
            }}
          >
            Yes
          </AcceptButton>
          <DeclineButton
            onClick={() => {
              dispatch(saveConversationClipboardHistoryViewHidden());
              dispatch(
                leaveConversation(currentUserId, currentConversationId, false)
              );
            }}
          >
            No
          </DeclineButton>
        </Row>
      </ModalDialog>
    </Overlay>
  );
};

export { GeneralConversationClipboardHistoryDialog };
