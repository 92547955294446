import React from "react";
import {
  Wrapper,
  Body,
  ConversationIcon,
  Name,
  MessageCount,
  IconWrapper
  // MetaData
} from "./ConversationItem.style";
import { LeaveIcon } from "foundations/components/icons/LeaveIcon";
import useHover from "foundations/hooks/useHover";
import { DEFAULT_CONVERSATION } from "features/currentConversation/currentConversationModel";
import { ConversationFragment } from "../MyConversations";
// import { Tooltip } from "react-lightweight-tooltip";

interface ConversationItemProps {
  selected: boolean;
  id: string;
  name: string;
  conversation: ConversationFragment;
  unreadMessageCount: number;
  onClick: () => void;
  onLeave: () => void;
  onJoin: () => void;
}

/**
 * Show a single joined conversation
 *
 * Similiar to ConversationDescription but with different style and more functionality
 */
const ConversationItem = ({
  selected,
  id,
  name,
  conversation,
  onClick,
  onLeave,
  onJoin,
  unreadMessageCount
}: ConversationItemProps) => {
  const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  const canLeave: boolean = id !== DEFAULT_CONVERSATION;

  let isGeneralEnquiry = name?.includes("GeneralEnquiry") ? true : false; // "CaseEnquiry"; // todo move to d.ts
  let isCaseEnquiry = name?.includes("CaseEnquiry") ? true : false;
  let icon = isGeneralEnquiry ? "G" : isCaseEnquiry ? "C" : "U";
  let strippedName = name
    ?.replace("GeneralEnquiry ID: ", "")
    ?.replace("CaseEnquiry ID: ", "");

  return (
    <Wrapper
      {...hoverProps}
      selected={selected}
      emphasized={unreadMessageCount > 0}
      onClick={onClick}
    >
      <Body>
        <ConversationIcon selected={selected}>{icon}</ConversationIcon>
        <Name>{strippedName}</Name>
        {/* <Tooltip
          content={[
            <div>{contextId}</div>,
            <div>{userName}</div>,
            `unread messages: ${unreadMessageCount}`
          ]}
        >
          <Name>{name}</Name>
        </Tooltip> */}
      </Body>
      {/* {isHovering && canLeave ? (
        <IconWrapper
          onClick={e => {
            e.stopPropagation();
            onJoin();
          }}
        >
          <JoinIcon title="Accept" selected={selected} />
        </IconWrapper>
      ) : (
        unreadMessageCount > 0 && (
          <MessageCount>{unreadMessageCount}</MessageCount>
        )
      )} */}
      {isHovering && canLeave ? (
        <IconWrapper
          onClick={e => {
            e.stopPropagation();
            onLeave();
          }}
        >
          <LeaveIcon title="Close" selected={selected} />
        </IconWrapper>
      ) : (
        unreadMessageCount > 0 && (
          <MessageCount>{unreadMessageCount}</MessageCount>
        )
      )}
    </Wrapper>
  );
};

export { ConversationItem };
