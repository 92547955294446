import { ThunkAction } from "main/storeTypes";

export const addSpaceMembers = (
  userId: string,
  conversationId: string
): ThunkAction<Promise<void>> => {
  return (dispatch, getState, context) => {
    return new Promise((resolve: any, reject: any) => {
      context.pubnub.api.addMembers(
        {
          spaceId: conversationId,
          users: [{ id: userId }]
        },
        (status: any, response: any) => {
          if (!status.error) {
            resolve();
          } else if (status.statusCode === 409) {
            resolve();
          } else if (status.statusCode === 400) {
            resolve();
          } else {
            reject();
          }
          console.log(JSON.stringify(status));
        }
      );
    });
  };
};
