import { ThunkAction } from "main/storeTypes";
// import { fetchMessageHistory, fetchSpaceById } from "pubnub-redux";
import { saveChatHistory } from "./saveChatHistoryCommand";

export const saveLastKnownChatHistory = (
  userId: string,
  conversationId: string,
  saveToNotes: boolean
): ThunkAction<Promise<void>> => {
  return dispatch => {
    return dispatch(saveChatHistory(userId, conversationId, saveToNotes));
  };
};
