export default (timetoken: string) => {
  const tt = parseInt(timetoken);
  const unixTimestamp = tt / 10000000;

  const localDateTime = new Date(unixTimestamp * 1000);

  let month = "" + (localDateTime.getMonth() + 1),
    day = "" + localDateTime.getDate(),
    year = localDateTime.getFullYear(),
    hour = localDateTime.getHours(),
    minutes = localDateTime.getMinutes(),
    seconds = localDateTime.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let date = [day, month, year].join("/");

  return `${date} ${hour}:${minutes}:${seconds}`;
};
