import styled from "styled-components/macro";
import { motion } from "framer-motion";
// import { space, layout, color } from "styled-system";

// export const Box = styled.div`
//   ${space}
//   ${layout}
//   ${color}
// `;

export const StyledText = styled.h2`
  color: black;
  text-align: center;
  font-weight: 200;
`;

export const AcceptButton = styled.button`
  text-transform: uppercase;
  align-self: center;
  width: 200px;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  padding: 20px 0;
  background: #c13931;
  border: none;
  margin: 5px;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

export const DeclineButton = styled.button`
  text-transform: uppercase;
  align-self: center;
  width: 200px;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 500;
  color: white;
  border-radius: 5px;
  padding: 20px 0;
  background: #d1a81e;
  border: none;
  margin: 5px;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

// export const Header = styled.p`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   @media ${props => props.theme.breakpoint.mediaQuery.small} {
//     padding: 0 10px;
//   }
// `;

// export const Title = styled.h2`
//   font-size: 20px;
//   color: #585858;
//   font-weight: 500;
// `;

export const Overlay = styled.div<{ displayed: boolean }>`
  position: fixed;
  display: ${props => (props.displayed ? "flex" : "none")};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;

  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    background-color: #ffffff;
  }
`;

export const ModalDialog = styled(motion.section)`
  width: 28%;
  position: absolute;
  left: 80%;
  top: 50%;
  left: 50%;
  z-index: 500;
  padding: 16px 35px;
  text-align: center;
  transform: translate(-50%, -50%);
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 3px 11px 17px 10px #717171;

  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    padding: 0 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;
